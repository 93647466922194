import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/Layout/layout"
import ImageTop from "../components/ImageTop/ImageTop"

const AboutPage = () => {
  const data = useStaticQuery(query)
  data.strapiAbout.description = data.strapiAbout.description.split(`img src="/upload`).join(`img src="${process.env.GATSBY_API_URL}/upload`);
  return (
    <Layout seo={data.strapiHomepage.seo}>
      <div>
        <ImageTop
          image={ data.strapiAbout.image }
          sizes='100vw'
          title={data.strapiAbout.title}
        />
        <div className="uk-section">
          <div className="uk-container uk-container">
            <div className="ck-content" dangerouslySetInnerHTML={{ __html: data.strapiAbout.description }} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

const query = graphql`
  query {
    strapiAbout{
      title
      description
      image{
          localFile{
              childImageSharp {
                  fixed(width: 1800, height: 1200) {
                      src
                  }
              }
          }
      }
    }
    strapiHomepage {
      seo {
        metaTitle
        metaDescription
      }
    }
  }
`

export default AboutPage