import React from 'react';
import LazyLoadingImage from "./LazyLoading"

const BlogTop = ({image, sizes, title}) =>{
  return (
    <div className="BlogTop">
      <LazyLoadingImage
        image={ image.localFile.childImageSharp.fixed}
        className="BlogTopImage"
        sizes={sizes}
        isLazy={ false }
      />
      <div className="BlogTopText">
        <h1 className="BlogTopTitle">
          {title}
        </h1>
      </div>
    </div>
  );
};
export default BlogTop;
