import React, { useEffect, useState, useRef } from 'react';

const placeHolder =
// eslint-disable-next-line max-len
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8+PnzfwAJjAPYgyf1SAAAAABJRU5ErkJggg==';

const LazyLoadingImage = ({
                            image, className, style, sizes = '', isLazy = true, onClick,
                          }) => {
  const [ imageSrc, setImageSrc ] = useState(placeHolder);
  const [ imageSrcSet, setImageSrcSet ] = useState('');
  const imageRef = useRef(null);

  const { src, thumbnailTiny, thumbnailSmall, thumbnailMedium, thumbnailBig, thumbnailLarge, alt } =
    image?.src ? image : {};

  const srcSet = `
    ${ thumbnailTiny ? thumbnailTiny + ' 420w,' : '' }
    ${ thumbnailSmall ? thumbnailSmall + ' 780w,' : '' }
    ${ thumbnailMedium ? thumbnailMedium + ' 1160w,' : '' }
    ${ thumbnailBig ? thumbnailBig + ' 1440w,' : '' }
    ${ thumbnailLarge ? thumbnailLarge + ' 1700w' : '' }
  `;

  useEffect(() => {
    let observer;
    let didCancel = false;
    const lazyLoadingSupported = 'loading' in HTMLImageElement.prototype;

    if (isLazy) {
      if (!lazyLoadingSupported) {
        if (imageRef && imageSrc === placeHolder) {
          if (IntersectionObserver) {
            observer = new IntersectionObserver(
              (entries) => {
                entries.forEach((entry) => {
                  if (!didCancel && (
                    entry.intersectionRatio > 0 || entry.isIntersecting
                  )) {
                    setImageSrc(src);
                    setImageSrcSet(srcSet);
                  }
                });
              },
              {
                threshold: 0.01,
                rootMargin: '75%',
              },
            );
            imageRef?.current && observer.observe(imageRef.current);
          } else {
            setImageSrc(src);
            setImageSrcSet(srcSet);
          }
        }
      } else {
        setImageSrc(src);
        setImageSrcSet(srcSet);
      }
    }
    return () => {
      if (!lazyLoadingSupported && isLazy) {
        didCancel = true;
        if (observer?.unobserve) {
          imageRef?.current && observer.unobserve(imageRef.current);
        }
      }
    };
  }, [ src ]);

  return (
    <img
      ref={ imageRef }
      alt={ alt || '' }
      src={ isLazy ? imageSrc : src }
      srcSet={ isLazy ? imageSrcSet : srcSet }
      sizes={ sizes }
      className={ className }
      style={ style }
      loading={ isLazy ? 'lazy' : undefined }
      onClick={ onClick }
    />
  );
};
export default LazyLoadingImage;