import React from "react"
import PropTypes from "prop-types"
import Nav from "../Navbar/nav"
import Seo from "../Seo/seo"
import Footer from "../Footer/footer"
import SideBar from "../SideBar/sidebar"
import Cookies from "../Cookies/cookies"

const Layout = ({ children, seo }) => {
  return (
    <>
      <Seo seo={seo} />
      <Nav />
      <main>
        {children}
      </main>
      <Cookies />
      <Footer />
      <SideBar />
    </>
  )
}
Layout.propTypes =
  {
    children: PropTypes.node.isRequired
  }


export default Layout